<template>
  <div style="padding: 0 30px 20px">
    <div style="color: #cd9f49">注：开通帐号风险由客户自己承担</div>
    <div class="flex-start order-timebox" style="width: 100%">
      <div class="flex-start order-number">
        <div class="number">员工姓名:</div>
        <el-input
          v-model="searchForm.userName"
          clearable
          size="mini"
          style="width: 120px"
        ></el-input>
      </div>
      <div class="flex-start order-number">
        <div class="number">手机号码:</div>
        <el-input
          v-model="searchForm.userPhone"
          clearable
          size="mini"
          style="width: 120px"
        ></el-input>
      </div>
      <div class="flex-start order-number">
        <div class="number">角色类型:</div>
        <el-select
          v-model="searchForm.roleId"
          placeholder="请选择"
          size="mini"
          clearable
          style="width: 120px"
          @change="onScreen"
        >
          <el-option
            v-for="item in roleType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex-start order-number">
        <div class="number">账号状态:</div>
        <el-select
          v-model="searchForm.userStatus"
          placeholder="请选择"
          size="mini"
          clearable
          style="width: 100px"
          @change="onScreen"
        >
          <el-option
            v-for="item in accuntState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="flex-start">
      <div class="order-reset" @click="onReset()">重置</div>
      <div class="order-badch" @click="getList()">查询</div>
      <div class="order-badch" :class="{ notAllowed: onExportBtn }" @click="onExport()">
        <i v-if="onExportBtn" class="el-icon-loading"></i>
        导出信息
      </div>
      <div class="order-badch" @click="add()">新增账号</div>
    </div>
    <div class="table-box">
      <el-table
        ref="topUpTable"
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ 'background-color': '#ccc', color: '#000000d9' }"
      >
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="id"
          label="账号ID"
          align="center"
          minWidth="80"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="员工姓名"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="userPhone"
          label="绑定手机号"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="role"
          label="角色类型"
          align="center"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="148"
        ></el-table-column>
        <el-table-column
          prop="userStatus"
          label="账号状态"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.userStatus == 1">使用中</span>
            <span v-if="scope.row.userStatus == 2">冻结</span>
            <span v-if="scope.row.userStatus == 3">注销</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              @click="edit(scope.row)"
              style="color: #cd9f49"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.userStatus == 1"
              :disabled="disabled"
              style="color: #cd9f49"
              type="text"
              size="small"
              @click="frozen(scope.row)"
              >冻结</el-button
            >
            <el-button
              v-if="scope.row.userStatus == 2"
              :disabled="disabled"
              style="color: #cd9f49"
              type="text"
              size="small"
              @click="thaw(scope.row)"
              >解冻</el-button
            >
            <el-button
              :disabled="disabled"
              style="color: #cd9f49"
              type="text"
              size="small"
              @click="detailsBtn(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="tablePage">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <AccountDialog
      :dialog="accountDialog"
      :headForm="headForm"
      :dialogTitle="accountDialogTitle"
      @onCloseDialog="onCloseDialog"
    ></AccountDialog>
  </div>
</template>

<script>
import { ROLE_TYPE, ACCUNT_STATE } from './enums'
import AccountDialog from './accountDialog.vue'
import {
  externalQueryPage,
  frozenUser,
  unfrozenUser,
  deleteUser,
  externExportExternalUser,
  externUserInfo
} from '@/api/api'
export default {
  components: {
    AccountDialog
  },
  props: {},
  data() {
    return {
      // 搜索
      searchForm: {
        userName: '',
        userPhone: '',
        roleId: '',
        userStatus: ''
      },
      // 角色类型
      roleType: [],
      // 账号状态
      accuntState: [],
      // 数据容器
      tableData: [],
      // 分页 第几页
      currentPage: 1,
      // 分页 多少页
      pageSize: 20,
      // 分页 总条数
      total: 0,
      // 按钮限制
      disabled: false,
      onExportBtn: false,
      // 弹窗
      accountDialog: false,
      accountDialogTitle: '新增账号',
      headForm: {}
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    this.roleType = ROLE_TYPE
    this.accuntState = ACCUNT_STATE
  },
  methods: {
    // 查询
    getList() {
      let data = {
        ...this.searchForm,
        myPage: {
          pageNum: this.currentPage,
          pageSize: this.pageSize
        }
      }
      externalQueryPage(data).then((res) => {
        if (res.code == '00000') {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.$message.error(res.desc)
        }
      })
    },
    onScreen() {
      this.currentPage = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.searchForm = {
        userName: '',
        userPhone: '',
        roleId: '',
        userStatus: ''
      }
      this.getList()
    },
    // 导出信息
    onExport() {
      if(this.onExportBtn) return;
      this.onExportBtn = true
      externExportExternalUser(this.searchForm).then((res) => {
        this.onExportBtn = false
        if (res.code == '00000') {
          console.log(res)
        }
      })
    },
    // 新增账号
    add() {
      this.headForm = {
        userPhone: '',
        userName: '',
        email: '',
        roleIds: [],
        manage: []
      }
      this.accountDialogTitle = '新增账号'
      this.accountDialog = true
    },
    // 编辑账号
    edit(obj) {
      this.disabled = true
      externUserInfo(obj.id)
        .then((res) => {
          this.disabled = false
          if (res.code == '00000') {
            this.headForm = {
              userPhone: res.data.userPhone,
              userName: res.data.userName,
              email: res.data.email,
              roleIds: res.data.roleIds,
              manage: res.data.manageCompanyIds,
              id: res.data.id
            }
            this.accountDialogTitle = `编辑账号信息`
            this.accountDialog = true
          } else {
            this.$message.error(res.desc)
          }
        })
        .catch(() => {
          this.$message.error('网络异常请重试！')
          this.disabled = false
        })
    },
    onCloseDialog(isshow = false) {
      if (isshow) this.getList()
      this.accountDialog = false
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    // 冻结
    frozen(obj) {
      this.$confirm(`确定要把 ${obj.userName} 账户冻结吗？`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.disabled = true
        frozenUser(obj.id)
          .then((res) => {
            this.disabled = false
            if (res.code == '00000') {
              this.getList()
            } else {
              this.$message.error(res.desc)
            }
          })
          .catch(() => {
            this.$message.error('网络异常请重试！')
            this.disabled = false
          })
      })
    },
    // 解冻
    thaw(obj) {
      this.$confirm(`确定要把 ${obj.userName} 账户解冻吗？`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.disabled = true
        unfrozenUser(obj.id)
          .then((res) => {
            this.disabled = false
            if (res.code == '00000') {
              this.getList()
            } else {
              this.$message.error(res.desc)
            }
          })
          .catch(() => {
            this.$message.error('网络异常请重试！')
            this.disabled = false
          })
      })
    },
    // 删除
    detailsBtn(obj) {
      this.$confirm(`确定要把 ${obj.userName} 账户删除吗？`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.disabled = true
        deleteUser(obj.id)
          .then((res) => {
            this.disabled = false
            if (res.code == '00000') {
              this.getList()
            } else {
              this.$message.error(res.data || res.desc)
            }
          })
          .catch(() => {
            this.$message.error('网络异常请重试！')
            this.disabled = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-box {
  padding: 20px 0;

  .tablePage {
    display: flex;
    justify-content: flex-end;
    padding: 25px;
  }
}
.order-timebox {
  margin: 10px 0 10px;
  .time-input {
    width: 30%;
  }
}
.order-badch {
  width: 80px;
  text-align: center;
  height: 26px;
  line-height: 26px;
  color: #fff;
  background: #cd9f49;
  font-size: 14px;
  margin-right: 15px;
}
.order-reset {
  width: 80px;
  text-align: center;
  height: 26px;
  line-height: 26px;
  border: 1px solid #333;
  font-size: 14px;
  margin-right: 15px;
}
.order-number {
  margin-right: 15px;
  .number {
    width: 80px;
  }
}
.button {
  display: flex;
  align-items: center;
  div {
    border-radius: 8px;
    border: 1px solid #000;
    text-align: center;
    width: 80px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    margin-left: 10px;
  }
  .search-btn {
    border-color: #cd9f49;
    background-color: #cd9f49;
    color: #fff;
  }
  .export-btn {
    background-color: #fff;
    color: #000;
  }
}
</style>