<template>
  <div>
    <!-- 查看审批文件 -->
    <el-dialog
      :visible="dialog"
      width="700px"
      :title="dialogTitle"
      center
      append-to-body
      @close="closeDialog"
    >
      <div class="dialogVisibleBox" v-loading="loading">
        <div class="form-box">
          <el-form
            ref="headForm"
            :model="headForm"
            label-width="120px"
            :rules="rules"
            :inline="true"
            size="small"
          >
            <el-form-item label="组织名称：" prop="companyName">
              <el-input
                v-model="headForm.companyName"
                style="width: 470px"
                placeholder="请输入组织名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="上级组织：">
              <div style="width: 470px">{{ headForm.parent }}</div>
            </el-form-item>
            <el-form-item label="组织类型：" prop="groupType">
              <div class="group">
                <el-radio-group
                  v-model="headForm.groupType"
                  style="display: flex; flex-wrap: wrap"
                >
                  <div
                    class="attr-name flex-start"
                    v-for="(item, index) in typeArr"
                    :key="index"
                  >
                    <el-radio
                      style="margin-right: 20px"
                      v-if="headForm.parentType < item.value"
                      :label="item.value"
                      >{{ item.label }}</el-radio
                    >
                  </div>
                </el-radio-group>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          :disabled="loading"
          size="small"
          type="warning"
          @click="submitBtn()"
          >提 交</el-button
        >
        <el-button size="small" @click="closeDialog()">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addCompany } from '@/api/api'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    headForm: {
      type: Object,
      default: () => {}
    },
    typeArr: {
      type: Array,
      default: () => []
    },
    dialogTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      options: [],
      rules: {
        companyName: [
          { required: true, message: '组织名称不能为空！', trigger: 'blur' }
        ],
        groupType: [
          { required: true, message: '请选择组织类型！', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 关闭窗口
    closeDialog(isshow = false, name) {
      this.loading = false
      this.$emit('update:dialog', false)
      this.$emit('onCloseDialog', isshow, name)
    },
    // 提交
    submitBtn() {
      this.$refs['headForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          let data = { 
            companyName: this.headForm.companyName,
            groupType: this.headForm.groupType,
            parentId: this.headForm.parentId
          }
          addCompany(data)
            .then((res) => {
              this.loading = false
              if (res.code === '00000') {
                this.$message.success('添加成功')
                this.closeDialog(true)
              } else {
                this.$message.error(res.desc)
              }
            })
            .catch(() => {
              this.$message.error('网络异常请重试！')
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
  height: 32px;
  display: flex;
  align-items: center;
}
</style>