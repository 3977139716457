// 角色类型
export const ROLE_TYPE = [
  {
    label: '全部',
    value: undefined
  },
  {
    label: '管理员',
    value: 5
  },
  {
    label: '审批员',
    value: 2
  },
  {
    label: '采购员',
    value: 3
  },
  {
    label: '商品管理员',
    value: 6
  }
]
// 账号状态
export const ACCUNT_STATE = [
  {
    label: '全部',
    value: undefined
  },
  {
    label: '使用中',
    value: 1
  },
  {
    label: '冻结',
    value: 2
  }
  // {
  //   label: '注销',
  //   value: 3
  // }
]