<template>
    <div class="myTopUp-page">
        <Header seet="4" />
        <el-row type="flex">
            <el-col :span="3" ></el-col>
            <el-col :span="17" style="z-index: 3;">
                <div class="breadcrumb-box">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>账号中心</el-breadcrumb-item>
                        <el-breadcrumb-item>公司账户管理</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="content-box">
                    <LeftHurdle style="margin-right: 20px" />
                    <div class="box-rights">
                        <div class="box-right-top">公司账户管理</div>
                        <Tabs :indexValue="indexValue" :listdata="listdata" @choice="choice"></Tabs>
                        <Organization v-if="indexValue == 1"></Organization>
                        <Account v-if="indexValue == 2"></Account>
                        <OtherConfig v-if="indexValue == 3"></OtherConfig>
                    </div>
                </div>
            </el-col>
            <el-col :span="3" ></el-col>
        </el-row>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import LeftHurdle from "@/components/Layout/leftHurdle.vue";
import Tabs from '../components/tabs.vue';
import Organization from './organization.vue';
import Account from './account.vue';
import OtherConfig from './otherConfiguration.vue';

export default {
    components: { 
        Header,
        Footer,
        LeftHurdle,
        Tabs,
        Organization,
        Account,
        OtherConfig
    },
    data() {
        return {
          listdata: [
              { value: 1, label: "组织机构管理" },
              { value: 2, label: "账号管理" },
              { value: 3, label: "其他配置" }
          ],
          indexValue: 1
        }
    },
    methods: {
      choice(value) {
        this.indexValue = value
      }
    }
}
</script>

<style lang="scss" scoped>

.myTopUp-page {
    .breadcrumb-box {
        margin-top: 30px;
        padding-bottom: 20px;   
        .el-breadcrumb {
          font-size: 18px;
        }
    }

    .content-box {
        display: flex;

        .box-rights {
            width: 1100px !important;
            border: 1px solid #d7d7d7;
            border-radius: 10px;

            .box-right-top {
                height: 40px;
                line-height: 40px;
                padding-left: 40px;
                background: #d7d7d7;
                border-bottom: 1px solid #d7d7d7;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                font-weight: bold;
            }

            .balance-box {
                width: 270px;
                height: 130px;
                border: 1px solid #d7d7d7;
                border-radius: 10px;
                margin: 20px;

                .title {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 10px;
                    margin: 10px 0;

                    span {
                        font-size: 14px;
                        color: #333;

                        &:nth-child(2){
                            color: #cd9f49;
                            font-weight: bold;
                            cursor: pointer;

                        }
                    }
                }
                .number {
                    font-size: 16px;
                    color: #aaa;
                    padding: 0 10px;
                }
            }

            .from-box {
                display: flex;
                padding: 0 20px;

                .button {
                    display: flex;
                    align-items: center;

                    div {
                        border-radius: 8px;
                        border: 1px solid #000;
                        text-align: center;
                        width: 80px;
                        height: 30px;
                        line-height: 30px;
                        cursor: pointer;
                        margin-left: 10px;
                    }
                    .search-btn {
                        border-color: #cd9f49;
                        background-color: #cd9f49;
                        color: #fff;
                    }
                    .export-btn {
                        background-color: #fff;
                        color: #000;
                    }
                }
                .from-box-text {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: column;
                    font-size: 13px;
                    margin-left: 10px;
                    color: rgb(105, 102, 102);
                }
            }

            .table-box {
                padding: 20px 0;

                .tablePage {
                    display: flex;
                    justify-content: flex-end;
                    padding: 25px;
                    
                }
            }

        }
    }
}
// 分页样式
::v-deep .el-pagination.is-background .el-pager {
    .active {
        background-color: #cd9f49 !important;
    }
    li:hover {
        color: #cd9f49 !important;
    }
}
</style>