<template>
    <div class="order_top">
        <div class="order-class flex-between">
            <div class="flex-start">
                <div
                    class="order-cut"
                    style="cursor: pointer;"
                    v-for="(
                        item, index
                    ) in listdata"
                    :key="index"
                    @click="changeAct(item.value)"
                >
                    <div
                        :class="[
                            indexValue === item.value
                                ? 'order-text'
                                : 'order-texts',
                        ]"
                    >
                        <div class="order-name">
                            {{ item.label }}
                        </div>
                    </div>
                    <div
                        :class="[
                            indexValue === item.value
                                ? 'order-line'
                                : '',
                        ]"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        listdata: {
            type: Array,
            default: () => {
                return []
            }
        },
        indexValue: {
            type: Number,
            default: 1
        }
    },
    data(){
        return {}
    },
    methods:{
        changeAct(value) {
            this.$emit('choice', value)
        }
    }
}
</script>

<style lang="scss" scoped>
    .order_top {
        padding: 15px 30px;
        .order-cut {
            margin-right: 30px;
            .order-name {
                position: relative;
            }
            .order-number {
                position: absolute;
                top: -7px;
                right: -34px;
                color: red;
                font-size: 16px;
            }
        }
        .order-text {
            color: #cd9f49;
        }
        .order-texts {
            color: #333;
            &:hover {
                color: #cd9f49;
            }
        }

        .order-line {
            border: 0.5px solid #cd9f49;
        }
        .order-number {
            // margin-right: 25px;
            .number {
                width: 30%;
            }
            .numbers {
                width: 30%;
            }
            .order-start {
                width: 78px;
            }
        }
        .tow-number{
            .el-input--suffix{
                width: 97%;
            }
        }
        .order-timebox {
            margin: 20px 0;
            .time-input {
                width: 30%;
            }
        }
        .el-input {
            width: 60%;
        }
        .orderTime {
            margin: 0 16px;
        }
        .zhi {
            margin: 0 10px;
        }
    }
</style>