<template>
  <div>
    <!-- 查看审批文件 -->
    <el-dialog
      :visible="dialog"
      width="700px"
      :title="dialogTitle"
      center
      append-to-body
      @close="closeDialog"
    >
      <div class="dialogVisibleBox" v-loading="loading">
        <div class="form-box">
          <el-form
            ref="headForm"
            :model="headForm"
            label-width="120px"
            :rules="rules"
            :inline="true"
            size="small"
          >
            <el-form-item label="员工姓名：" prop="userName">
              <el-input
                v-model="headForm.userName"
                style="width: 470px"
                placeholder="请输入员工姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="userPhone">
              <el-input
                v-model="headForm.userPhone"
                style="width: 470px"
                maxlength="11"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="绑定邮箱：">
              <el-input
                v-model="headForm.email"
                style="width: 470px"
                placeholder="请输入绑定邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item label="角色类型：" prop="roleIds">
              <el-checkbox-group
                v-model="headForm.roleIds"
                style="display: flex; flex-wrap: wrap"
              >
                <div
                  class="attr-name flex-start"
                  v-for="(item, index) in brand"
                  :key="index"
                >
                  <el-checkbox :label="item.id">{{ item.name }}</el-checkbox>
                </div>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="分管组织：" prop="manage">
              <el-cascader
                v-model="headForm.manage"
                :options="options"
                :props="{
                  multiple: true,
                  checkStrictly: true,
                  value: 'companyId',
                  label: 'companyName',
                  emitPath: false
                }"
                style="width: 470px"
                clearable
                filterable
              >
              </el-cascader>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          :disabled="loading"
          size="small"
          type="warning"
          @click="submitBtn()"
          >提 交</el-button
        >
        <el-button size="small" @click="closeDialog()">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { externalGroupData, externAladdUser, externUpdateCompany } from '@/api/api'
import { phoneReg } from '@/utils/regular'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    headForm: {
      type: Object,
      default: () => {
        return {
          userPhone: '',
          userName: '',
          email: '',
          roleIds: [],
          manage: []
        }
      }
    },
    dialogTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      brand: [
        {
          name: '管理员',
          id: "5"
        },
        {
          name: '审批员',
          id: "2"
        },
        {
          name: '采购员',
          id: "3"
        },
        {
          name: '商品管理员',
          id: "6"
        }
      ],
      options: [],
      mapList: [],
      rules: {
        userName: [
          { required: true, message: '员工姓名不能为空！', trigger: 'blur' }
        ],
        userPhone: [
          { required: true, message: '手机号码不能为空！', trigger: 'blur' },
          { pattern: phoneReg, message: '请输入正确的手机号码' }
        ],
        roleIds: [
          { required: true, message: '角色类型不能为空！', trigger: 'blur' }
        ],
        manage: [
          { required: true, message: '分管组织不能为空！', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    externalGroupData().then((res) => {
      if (res.code == '00000') {
        let data = res.data
        // 最终要产出的树状数据的数组
        const treeList = []
        // 所有项使用对象存储起来
        const map = {}
        data.forEach((item) => {
          // 把每项放到新的对象map里面
          map[item.companyId] = item
        })
        data.forEach((item) => {
          /*
        对于每一个元素来说，先找它的上级
        如果能找到，说明它有上级，则要把它添加到上级的children 中去
        如果找不到，说明它没有上级，直接添加到treeList
        */
          const parent = map[item.parentId]
          if (parent) {
            // 如果存在则表示item不是最顶层的数据
            if (!parent.children) {
              // 如果每个对象里面没有children,就加上
              parent.children = []
            }
            parent.children.push(item)
          } else {
            // 如果不存在，则是顶层数据
            treeList.push(item)
          }
        })
        this.options = treeList
        this.mapList = map
      }
    })
  },
  methods: {
    // 关闭窗口
    closeDialog(isshow = false) {
      this.loading = false
      this.$emit('update:dialog', false)
      this.$emit('onCloseDialog', isshow)
    },
    // 提交
    submitBtn() {
      this.$refs['headForm'].validate((valid) => {
        if (valid) {
          let { manage, ...obj } = this.headForm
          let manageCompanyIds = []
          let manageProjectIds = []
          manage.forEach((item) => {
            if (this.mapList[item].groupType == 5) {
              manageProjectIds.push(this.mapList[item].companyId)
            } else {
              manageCompanyIds.push(this.mapList[item].companyId)
            }
          })
          this.$confirm('确认提交？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.loading = true
            if (this.dialogTitle == '新增账号') {
              externAladdUser({ manageCompanyIds, manageProjectIds, ...obj })
                .then((res) => {
                  this.loading = false
                  if (res.code === '00000') {
                    this.$message.success('添加成功')
                    this.closeDialog(true)
                  } else {
                    this.$message.error(res.desc)
                  }
                })
                .catch(() => {
                  this.$message.error('网络异常请重试！')
                  this.loading = false
                })
            } else {
              externUpdateCompany({ manageCompanyIds, manageProjectIds, ...obj })
                .then((res) => {
                  this.loading = false
                  if (res.code === '00000') {
                    this.$message.success('编辑成功')
                    this.closeDialog(true)
                  } else {
                    this.$message.error(res.desc)
                  }
                })
                .catch(() => {
                  this.$message.error('网络异常请重试！')
                  this.loading = false
                })

            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.attr-name {
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
  .el_Input {
    display: block;
    margin-right: 10px;
  }
}
.dialogVisibleBox {
  width: 100%;
  // height: 380px;
  position: relative;

  .form-box {
    margin: 20px 0 0;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .title-box {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .btn {
      margin-left: 12px;
    }
  }
  .hint-box {
    width: 100%;
    font-size: 14px;
    color: #666;
  }
}
</style>