<template>
  <div style="padding: 0 30px 20px; display: flex; min-width: 1000px">
    <div class="organizat-box">
      <div class="organizat-top">组织架构树</div>
      <div class="organizat-cent">
        <el-form ref="ruleForm" style="padding: 10px 20px">
          <el-form-item>
            <el-input
              v-model="cardCount"
              style="width: 220px"
              placeholder="请输入关键字进行搜索"
              size="small"
            ></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-tree
            v-if="treeList.length > 0"
            ref="tree"
            class="tree-box-tree"
            :data="treeList"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            :props="{
              label: 'companyName'
            }"
            highlight-current
            default-expand-all
            @node-click="nodeClick"
          >
          </el-tree>
        </div>
      </div>
    </div>
    <div class="organizat-box">
      <div class="organizat-top">组织架构设置</div>
      <div class="organizat-cent organizat-infor">
        <el-form
          ref="form"
          :model="activeOrg"
          label-width="160px"
          @submit.native.prevent
        >
          <el-form-item label="组织名称：">
            <span>{{ activeOrg.label }}</span>
          </el-form-item>
          <el-form-item label="上级组织：">
            <span>{{ activeOrg.parent }}</span>
          </el-form-item>
          <el-form-item label="组织类型：">
            <el-button type="warning" size="small">{{
              activeOrg.type
            }}</el-button>
          </el-form-item>
        </el-form>
        <div style="text-align: left; margin-bottom: 20px">
          <el-button-group>
            <el-button
              :disabled="
                activeOrg.companyId === '' ||
                activeOrg.groupType == 3
              "
              type="warning"
              size="small"
              @click="editDialog()"
              >编辑</el-button
            >
            <el-button
              :disabled="activeOrg.companyId === '' || activeOrg.groupType == 5"
              type="warning"
              size="small"
              @click="addDialog()"
              >添加下级</el-button
            >
            <el-button
              :disabled="
                activeOrg.companyId === '' ||
                activeOrg.groupType == 3
              "
              type="warning"
              size="small"
              @click="delOrg()"
              >删除</el-button
            >
          </el-button-group>
        </div>
      </div>
    </div>
    <Add
      :dialog="AddDialog"
      :headForm="headForm"
      :dialogTitle="'添加下级组织信息'"
      :typeArr="MEMBER_STATE"
      @onCloseDialog="onCloseDialog"
    ></Add>
    <Edit
      :dialog="EditDialog"
      :headForm="headForm"
      :dialogTitle="'编辑组织信息'"
      @onCloseDialog="onCloseDialog"
    ></Edit>
  </div>
</template>

<script>
import { externalGroupData, deleteCompany, deleteProject } from '@/api/api'
import Edit from './editDialog.vue'
import Add from './addDialog.vue'
export default {
  components: {
    Edit,
    Add
  },
  props: {},
  data() {
    return {
      cardCount: '',
      treeList: [],
      mapList: [],
      MEMBER_STATE: [
        {
          label: '公司',
          value: 3,
          disabled: false
        },
        {
          label: '子公司',
          value: 4,
          disabled: false
        },
        {
          label: '项目',
          value: 5,
          disabled: false
        }
      ],
      activeOrg: {
        companyId: '',
        label: '组织机构名称',
        parent: '上级组织机构',
        type: '公司',
        groupType: '',
        grouptype: ''
      },
      EditDialog: false,
      AddDialog: false,
      headForm: {}
    }
  },
  watch: {
    cardCount(val) {
      this.$refs.tree.filter(val)
    }
  },
  mounted() {
    this.queryGroupData()
  },
  methods: {
    // 树形结构筛选
    filterNode(value, data) {
      if (!value) return true
      return data.companyName.indexOf(value) !== -1
    },
    onCloseDialog(isshow = false, name) {
      if (isshow) {
        this.queryGroupData()
        if (name) {
          this.activeOrg.label = name
        }
      }
      this.AddDialog = false
      this.EditDialog = false
    },
    // 获取树形结构数据
    queryGroupData() {
      externalGroupData().then((res) => {
        if (res.code == '00000') {
          let data = res.data
          // 最终要产出的树状数据的数组
          const treeList = []
          // 所有项使用对象存储起来
          const map = {}
          data.forEach((item) => {
            // 把每项放到新的对象map里面
            map[item.companyId] = item
          })
          data.forEach((item) => {
            /*
        对于每一个元素来说，先找它的上级
        如果能找到，说明它有上级，则要把它添加到上级的children 中去
        如果找不到，说明它没有上级，直接添加到treeList
        */
            const parent = map[item.parentId]
            if (parent) {
              // 如果存在则表示item不是最顶层的数据
              if (!parent.children) {
                // 如果每个对象里面没有children,就加上
                parent.children = []
              }
              parent.children.push(item)
            } else {
              // 如果不存在，则是顶层数据
              treeList.push(item)
            }
          })
          this.treeList = treeList
          this.mapList = map
        }
      })
    },
    // 节点被点击时的回调
    nodeClick(org) {
      const obj = this.MEMBER_STATE.find(
        (item) => String(item.value) == String(org.groupType)
      )
      this.activeOrg = {
        companyId: org.companyId,
        parentId: org.parentId,
        label: org.companyName,
        parent: this.mapList[org.parentId]
          ? this.mapList[org.parentId].companyName
          : '',
        groupType: org.groupType,
        type: (obj && obj.label) || '暂无数据'
      }
    },
    // 添加下级
    addDialog() {
      this.headForm = {
        companyName: '',
        parent: this.activeOrg.label,
        groupType: this.activeOrg.groupType + 1,
        parentType: this.activeOrg.groupType,
        parentId: this.activeOrg.companyId
      }
      console.log(this.headForm)
      this.AddDialog = true
    },
    // 编辑
    editDialog() {
      let str = this.MEMBER_STATE.filter(item => {
        // 这里写你要的返回那些符合条件的逻辑，比如不是‘a’
        return item.value == this.activeOrg.groupType //  注意一定要 return , ture 则返回当前x，否则不返回当前x
      })[0].label
      console.log(str)
      this.headForm = {
        companyName: this.activeOrg.label,
        parent: this.activeOrg.parent,
        groupType: this.activeOrg.groupType,
        groupName: str,
        companyId: this.activeOrg.companyId,
        parentId: this.activeOrg.parentId
      }
      this.EditDialog = true
    },
    // 删除
    delOrg() {
      let apis = null
      if (this.activeOrg.groupType == 5) {
        apis = deleteProject
      } else {
        apis = deleteCompany
      }
      this.$confirm('此操作将删除该组织机构，是否继续？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apis(this.activeOrg.companyId)
          .then((res) => {
            this.loading = false
            if (res.code === '00000') {
              this.$message.success('删除成功')
              this.queryGroupData()
            } else {
              this.$message.error(res.desc)
            }
          })
          .catch(() => {
            this.$message.error('网络异常请重试！')
            this.loading = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.organizat-box {
  width: 49.9%;
  margin-right: 0.7rem;
}
.organizat-top {
  color: #ffff;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 10px 15px;
  background: #cd9f49;
  font-size: 16px;
}
.organizat-cent {
  min-height: 350px;
  border: 1px solid #d7d7d7;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.tree-box-tree {
  max-height: 400px;
  overflow: auto;
}
// 滚动条
.tree-box-tree::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.tree-box-tree::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #e5e5e5;
}
.tree-box-tree::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ffffff;
}
.organizat-infor {
  color: black;
  padding: 25px 60px;
  font-size: 18px;
}
</style>