<template>
    <div style="padding: 0 30px 20px">
        <el-form label-position="left" label-width="130px" :model="form">
            <el-form-item label="订单是否审批：" required>
                <el-switch 
                    v-model="form.switch"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :disabled="disabled"
                    @change="switchFn()"
                    v-loading="loading"
                ></el-switch>
                <el-button type="text" v-if="disabled" @click="getStatus()" style="margin: 0 12px;">重新获取</el-button>
            </el-form-item>
        </el-form>
        <div class="tips">
            PS：必须有设置账号角色类型为审批员才生效
        </div>
    </div>
</template>

<script>
import { needAuditor, needAuditorStatus } from '@/api/api'

export default {
    name: 'otherConfig',
    data() {
        return {
            form: {
                switch: false
            },
            loading: true,
            disabled: false
        }
    },
    created() {
        this.getStatus()
    },
    methods: {
        getStatus() {
            this.loading = true
            needAuditorStatus().then(res => {
                this.loading = false
                this.disabled = false
                if(res.code == '00000' && res.success){
                    this.form.switch = res.data == 0 ? true : false;
                } else {
                    this.$message.error(res.desc);
                }
            }).catch( _ => {
                this.loading = false
                this.disabled = true
                console.log(_.message);
            })
        },
        switchFn() {
            const loading = this.$loading({
                lock: true,
                text: '正在执行中···',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.4)'
            });
            needAuditor().then(res => {
                loading.close();
                if(res.code == '00000' && res.success){
                    this.$message.success(res.desc);
                } else {
                    this.$message.error(res.desc);
                }
            }).catch( _ => {
                loading.close();
                console.log(_.message);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.tips {
    color: #999;
    font-size: 13px;
}
</style>
